import {useStaticQuery, graphql} from "gatsby"

const useMdxTocData = (slug) => {
  const data = useStaticQuery(graphql`
      query {
          allMdx {
              edges {
                  node {
                      headings {
                          value
                          depth
                      }
                      slug
                      parent {
                          ... on File {
                              mtime
                          }
                      }
                  }
              }
          }
      }
  `)

  const normalisedSlug = slug.endsWith('/') ? slug.substr(0, slug.length-1) : slug

  let filteredData = data.allMdx.edges.filter((mdx) => mdx.node.slug === normalisedSlug)
  if (filteredData.length > 0) {
    return filteredData[0].node
  } else {
    // Try to filter with trailing slash
    filteredData = data.allMdx.edges.filter((mdx) => mdx.node.slug === `${normalisedSlug}/`)
    return filteredData.length > 0 ? filteredData[0].node : null
  }
}

export default useMdxTocData;