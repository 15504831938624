import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import CodeBlock from "../CodeBlock";
import useMdxTocData from "../../hooks/use-mdx-data";
import HeadlineBlock from "../HeadlineBlock";
import ArticleTemplate from "../../templates/article-template";

const ArticleLayout = ({ children, location, pageContext }) => {
  const slug = location.pathname.substr(1 + process.env.SITE_PATH_PREFIX.length);
  const {
    breadcrumb: {crumbs},
  } = pageContext

  const components = {
    pre: CodeBlock,
    h1: HeadlineBlock.H2,
    h2: HeadlineBlock.H3,
    h3: HeadlineBlock.H4,
    h4: HeadlineBlock.H5,
    h5: HeadlineBlock.H6,
    h6: HeadlineBlock.H6,
  }
  const { frontmatter } = pageContext;

  const [toc, setToc] = React.useState(null);
  const mdxData = useMdxTocData(slug);

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (mdxData) setToc(mdxData.headings);
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <ArticleTemplate
      title={frontmatter.title}
      frontmatter={frontmatter}
      toc={toc}
      modifiedGmt={frontmatter.modified || mdxData.parent.mtime}
      performTime={undefined}
      createdGmt={undefined}
      crumbs={crumbs}
      slug={slug}
    >
      <MDXProvider components={components}>
        <div className={'article'}>{children}</div>
      </MDXProvider>
    </ArticleTemplate>
  )
}

export default ArticleLayout;
