import React from 'react'
import {headlineToId} from "../../utils/links";

const render = (level) => ({children}) => {
  switch(level) {
    case 1: return <h1 id={headlineToId(children)}>{children}</h1>
    case 2: return <h2 id={headlineToId(children)}>{children}</h2>
    case 3: return <h3 id={headlineToId(children)}>{children}</h3>
    case 4: return <h4 id={headlineToId(children)}>{children}</h4>
    case 5: return <h5 id={headlineToId(children)}>{children}</h5>
    default: return <h6 id={headlineToId(children)}>{children}</h6>
  }
}

const HeadlineBlock = {
  H1: render(1),
  H2: render(2),
  H3: render(3),
  H4: render(4),
  H5: render(5),
  H6: render(6)
}

export default HeadlineBlock;