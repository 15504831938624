import React from 'react'
import usePrism from "../../hooks/use-prism";

const CodeBlock = ({children, className}) => {
  const language = children.props.className ? children.props.className.replace(/language-/, '') : 'javascript'
  const container = React.useRef();

  usePrism(container);

  return (
    <pre ref={container}>
      <code className={`language-${language}`}>
        {children.props.children}
      </code>
    </pre>
  )
}

export default CodeBlock